.about__main {
  padding: 1rem;
}

.about__main > div > h2 {
  text-decoration: underline;
}

.about__links {
  display: none;
}

@media screen and (max-width: 478px) {
  .about__links {
    display: flex;
    padding-bottom: 2.5rem;
  }
  .about__links a {
    text-decoration: underline;
    color: var(--primary-color);
    font-size: 1.25rem;
  }
}
