footer {
  height: 2.5rem;
  border-top: 2px solid var(--bg-contrast);
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
  padding: 10px 20px;
  font-size: 1.25rem;
}

.footer__contact {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

footer a {
  text-decoration: none;
  color: var(--primary-color);
  padding: 2px 5px;
}

footer a:hover {
  /* background-color: var(--secondary-color);
    color: var(--bg-color); */
  border-radius: 0.5em;
  cursor: pointer;
}

@media screen and (max-width: 431px) {
  .footer__contact > a {
    display: none;
  }

  footer {
    gap: 40px;
    justify-content: center;
  }
}
