:root {
  --bg-color: #282c34;
  --primary-color: #fefefe;
  --secondary-color: #61dafb;
  --bg-contrast: rgb(127, 127, 127);
}

* {
  margin: 0;
  padding: 0;
}

p {
  font-size: 1.25rem;
}

html {
  min-height: 100vh;
}

body {
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: var(--primary-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.external-link-button {
  font-size: 1.25rem;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  background-color: var(--primary-color);
  padding: 0.5rem;
  margin: 1rem;
  border: solid 2px var(--bg-color);
  transition: all 0.2s ease;
  border-radius: 0.5rem;
  color: var(--bg-color);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.external-link-button:hover {
  background-color: var(--bg-color);
  cursor: pointer;
  transition: all 0.2s ease;
  border: solid 2px var(--primary-color);
  color: var(--primary-color);
}
