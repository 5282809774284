header {
  height: 2.5rem;
  border-bottom: 2px solid var(--bg-contrast);
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5rem;
  padding: 10px 20px;
}

header a {
  text-decoration: none;
  color: var(--primary-color);
  padding: 2px 5px;
  font-weight: bold;
  font-size: 1.25rem;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
}

header > a:hover {
  /* background-color: var(--secondary-color);
  color: var(--bg-color); */
  border-radius: 0.5em;
  cursor: pointer;
  text-decoration: underline;
}

.header__title {
  flex-grow: 1;
}

.side-menu-container {
  display: none;
}

@media screen and (max-width:980px) {
  header > a {
    display: none;
  }

  .header__title {
    flex-grow: 0;
  }

  header {
    justify-content: center;
  }

  .side-menu-btn {
    display: block;
    position: absolute;
    left: 270px;
    top: 10px;
    width: 48px;
    height: 48px;
  }

  .side-menu-container {
    display: block;
    position: absolute;
    left: -250px;
    top: 0px;
    z-index: 3;
    transition: all 200ms ease-in-out;
  }

  .side-menu-container.open {
    left:0;
  }

  .side-menu {
    width: 250px;
    display:flex;
    flex-direction: column;
    background-color: var(--primary-color);
    justify-content: center;
  }

  .side-menu > a {
    padding: 1rem;
    text-decoration: none;
    color: var(--bg-color);
    border-bottom: 1px solid var(--bg-color);
  }
}