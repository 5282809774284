.project-card {
  border: 2px solid white;
  border-radius: 1em;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25vw;
  min-width: 250px;
}

.project-card:hover {
  box-shadow: 5px 10px 10px 0px var(--secondary-color);
  width: calc(25vw + 25px);
}

.project-image {
  border-radius: 1em;
}

.project-card > h2 {
  border-bottom: 2px solid var(--bg-contrast);
  width: 100%;
  text-align: center;
}

.project-card__buttons {
  display: flex;
  flex-direction: row;
  border-top: 2px solid var(--bg-contrast);
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
}

.subheading {
  text-decoration: underline;
}
