.contact-method-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 3rem;
    row-gap: 3rem;
    align-items: center;
    height: 100%;
    min-height: 100%;
    flex-grow: 1;
}

.contact-method {
    border: 2px solid white;
    border-radius: 1rem;
    width: 300px;
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    align-items: center;
    justify-content: center;
    padding: 1rem 0.75rem;
}

.contact-method:hover {
    cursor: pointer;
    box-shadow: 5px 10px 10px 0px var(--secondary-color);
    width: 305px;
    height: 205px;
}

.contact-method > svg {
    width: 100px;
    height: 100px;
}