.title {
  font-size: 112px;
  font-family: "Courier New", Courier, monospace;
  color: var(--secondary-color);
  display: flex;
  justify-content: center;
  font-weight: 400;
  overflow: hidden;
}

.title-text {
  padding-top: 3rem;
  flex-basis: 35vw;
}

.title__top {
  display: flex;
  justify-content: start;
  position: relative;
}

.title__bottom {
  display: flex;
  justify-content: end;
  position: relative;
}

.title__top > span {
  flex-basis: auto;
}

@media screen and (max-width: 478px) {
  .title {
    font-size: 5rem;
  }
  .home-buttons {
    display: none;
  }
}

@media screen and (min-width: 479px) {
  .home-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .icon-button {
    display: flex;
    flex-direction: row;
    column-gap: 0.75rem;
  }
}
